import React, { useCallback, useEffect, useState } from "react";
import { pdf } from "@react-pdf/renderer";
import FileSaver from "file-saver";
import { FiUsers } from "react-icons/fi";
import { MdPictureAsPdf } from "react-icons/md"; // Ícone do PDF
import api from "../../../services/api";
import Button from "../../../template/components/Button";
import Layout from "../../../template/components/Layout";
import MainContainer from "../../../template/components/MainContainer";
import Pagination from "../../../template/components/Pagination";
import SearchBar from "../../../template/components/SearchBar";
import Table from "../../../template/components/Table";
import Loading from "../../components/Loading";
import { dialogBox } from "../../../template/utils/dialogBox";
import { parseJsonToTableDataList } from "../../../template/utils/parser";
import { Container, CustomReactSelect, FooterButtonContainer } from "./styles";
import DocumentPDF from "./components/CartaoPDF"; // Componente de PDF

// Cabeçalho da tabela (removido "Ação")
const fields = [
  "Nome",
  "CPF",
  "Telefone",
  "E-mail",
  "Cargo",
  "Data de Expiração",
];

const selectOptions2 = [
  { label: "Ativos", value: 2 },
  { label: "Inativos", value: 1 },
];

const GetFormatedDate = (date: Date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + (d.getDate() + 1),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("/");
};

const GerenciarFuncionario = () => {
  const [gerenciarfuncionario, setGerenciarFuncionario] = useState<any[]>([]);
  const [opcaoExpiracao, setOpcaoExpiracao] = useState<Number>(2);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [searchedValue, setSearch] = useState<string>("");
  const [downloading, setDownloading] = useState<boolean>(false); // Indicador de download
  const [
    isLoadingGerenciarFuncionario,
    setIsLoadingGerenciarFuncionario,
  ] = useState<boolean>(false); // Corrigido

  const itemsPerPage = 20;
  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  const loadGerenciarFuncionario = useCallback(async () => {
    try {
      setIsLoadingGerenciarFuncionario(true); // Definir o carregamento como true
      const { data } = await api.get(
        `funcionarios-laticinios/?limit=${itemsPerPage}&offset=${offset}${
          isNaN(Number(searchedValue))
            ? `&search=${searchedValue}`
            : `&documento__numero__icontains=${searchedValue}`
        }&is_active=${
          opcaoExpiracao === 1 ? false : true
        }&is_not_gestor_laticinio=true&ordering=-updated_at`
      );

      const { results, count } = data;
      setGerenciarFuncionario(
        parseJsonToTableDataList(parseGerenciarFuncionario(results), [
          "nome",
          "cpf",
          "telefone",
          "email",
          "cargo",
          "expiracao",
        ])
      );

      setTotal(count);
    } catch (error) {
      notify("error", "Erro no servidor");
    } finally {
      setIsLoadingGerenciarFuncionario(false);
    }
  }, [offset, searchedValue, opcaoExpiracao, notify]);

  useEffect(() => {
    loadGerenciarFuncionario();
  }, [loadGerenciarFuncionario]);

  function parseGerenciarFuncionario(gerenciarfuncionario: any[]) {
    let tmp = [];
    for (var i = 0; i < gerenciarfuncionario.length; i++) {
      const object = {
        id: gerenciarfuncionario[i].id,
        nome: gerenciarfuncionario[i]?.pessoa.nome,
        telefone: gerenciarfuncionario[i].pessoa.telefone
          ? gerenciarfuncionario[i].pessoa.telefone
          : "NÃO CADASTRADO",
        email: gerenciarfuncionario[i]?.usuario?.email
          ? gerenciarfuncionario[i].usuario.email
          : "NÃO CADASTRADO",
        cpf: gerenciarfuncionario[i]?.documento,
        expiracao: GetFormatedDate(gerenciarfuncionario[i]?.data_expiracao),
        cargo: gerenciarfuncionario[i]?.cargo,
      };
      tmp.push(object);
    }
    return tmp.sort();
  }

  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  const gerarTodosCartoes = async () => {
    setDownloading(true);
    try {
      let queryTablePaginada = `/cartoes-laticinios/arquivo/?search=${searchedValue}`;

      const response = await api.get(queryTablePaginada, {
        responseType: "blob",
      });

      setDownloading(false);

      const blobURL = URL.createObjectURL(response.data);

      window.open(blobURL);
    } catch (error) {
      setDownloading(false);
      notify("error", "Erro ao gerar o PDF.");
    }
  };

  return (
    <Layout>
      <MainContainer titlePage="Cartão Funcionários" iconPage={<FiUsers />}>
        <Container>
          <div className="row">
            <CustomReactSelect
              name="expiracao"
              className="space"
              options={selectOptions2}
              defaultValue={{ label: "Ativos", value: 2 }}
              onChange={(e: any) => {
                setOpcaoExpiracao(e.value);
                setPage(1);
              }}
              noOptionsMessage={() => "Nenhum papel encontrado"}
            />
            <div className="searchBar space">
              <SearchBar placeholder="Nome ou CPF" submitHandler={setSearch} />
            </div>
          </div>

          {isLoadingGerenciarFuncionario ? (
            <Loading />
          ) : (
            <>
              <Table
                fields={fields}
                rows={gerenciarfuncionario}
                hasSelection={false}
                hasSelectionAll={false}
              />
              <div className="containerFooterTable">
                <div className="visualizandoInfo">
                  {gerenciarfuncionario.length > 0 && !downloading && (
                    <FooterButtonContainer>
                      <Button
                        onClick={gerarTodosCartoes}
                        name="Baixar Todos os Cartões"
                        color="blue"
                        iconButtom={<MdPictureAsPdf />}
                      />
                    </FooterButtonContainer>
                  )}
                  Visualizando {gerenciarfuncionario.length} de um total de{" "}
                  {total} registros
                </div>

                <Pagination
                  itemCount={total}
                  itemsPerPage={itemsPerPage}
                  selectedPage={page}
                  handlePageChange={changePage}
                  maxPages={5}
                />
              </div>
            </>
          )}
        </Container>
      </MainContainer>
    </Layout>
  );
};

export default GerenciarFuncionario;
