import React from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";

import Dashboard from "./pages/Dashboard";

import Login from "./template/components/Login";

import { isAuthenticated } from "./services/auth";
import PrestarConta from "./pages/PrestarConta";
import CartaoFuncionario from "./pages/Relatorios/CartaoFuncionario";
import GerenciarFuncionario from "./pages/GerenciarFuncionario";
import RotaEntrega from "./pages/RotaEntrega";
import RelatorioEntregasLaticinio from "./pages/Relatorios/RelatorioEntregasLaticinio";

interface Iprops {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
}

const PrivateRoute = ({ component: Component, ...rest }: Iprops) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/"
          component={() => (
            <Login
              logoName="logotest1"
              initialPagePath="/dashboard"
              subtitleModulo="Gestor Laticínio"
            />
          )}
        />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/prestar_conta" component={PrestarConta} />
        <PrivateRoute
          path="/gerenciar_funcionario"
          component={GerenciarFuncionario}
        />
        <PrivateRoute path="/rota_entrega" component={RotaEntrega} />
        <PrivateRoute
          path="/relatorios/entregas_ponto"
          component={RelatorioEntregasLaticinio}
        />
        <PrivateRoute
          path="/relatorios/cartao_funcionario"
          component={CartaoFuncionario}
        />
        <Route path="*" component={() => <h1>Page not found</h1>} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
