import styled from "styled-components";
import colors from "../../template/styles/colors";
import arrow from "../../template/assets/icons/arrow.png";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  .custom-label-file {
    background-color: #0e4f9f;
    color: white;
    margin-top: 10px;
    height: 30px;
    border-radius: 5%;
  }

  .custom-label-file > svg {
    color: white;
  }

  .error-input {
    border: 2px solid ${colors.redError};
    box-shadow: 1px 1px 5px ${colors.redError};
  }
  .error-message {
    color: ${colors.redError};
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    display: block;
  }

  .CustomSelect {
    height: 39px;
    width: -webkit-fill-available;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    box-sizing: border-box;
    color: ${colors.ultraDarkGrey};
    border: 1px solid #c8ced3;
    /* border: 1px solid${colors.bluePLP}; */
    box-sizing: border-box;
    border-radius: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 8px 35px 8px 8px;
    background: url(${arrow}) 95% / 10px no-repeat;
    background-color: ${colors.light};
  }

  span div button {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
`;

export const CustomLabel = styled.label`
  color: #0e4f9f;
  display: block;
  font-weight: bold;
  font-size: 15px;
`;

export const Content = styled.div`
  .filter-select {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
  }
  .filter-search {
    margin-top: 37px;
  }
  .select-box {
    display: flex;
  }
  .table-options {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }

  .separator {
    margin-right: 20px;
  }
  .separator-middle {
    margin-right: 20px;
    margin-left: 20px;
  }

  .select-quinzena {
    // margin-left: 40px;
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
  }
`;

export const UploadButton = styled.button`
  background-color: ${colors.bluePLP};
  height: 35px;
  padding: 0 5px 0 5px;
  display: inline-block;
  border-radius: 8px;
  border: 1px solid #c8ced3;
  :hover {
    background-color: ${colors.blueOfficial};
    color: ${colors.light};
  }
`;

export const DownloadButton = styled.button`
  background-color: ${colors.bluePLP};
  height: 35px;
  padding: 0 5px 0 5px;
  display: inline-block;
  border-radius: 8px;
  border: 1px solid #c8ced3;
  :hover {
    background-color: ${colors.blueOfficial};
    color: ${colors.light};
  }
`;

export const DivLocalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0 10px 0;
`;

export const CentralizerText = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const UploadButtonText = styled.span`
  color: ${colors.light};
  font-size: 12px;
`;

export const DownloadButtonText = styled.span`
  color: ${colors.light};
  font-size: 12px;
`;

export const UploadLabel = styled.label`
  border: 1px solid #c8ced3;
  box-sizing: border-box;
  border-radius: 8px;
  height: 38px;
  padding: 8px 12px 8px 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: ${(props) => props.color};
  flex: 1;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UploadDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Separator = styled.div`
  height: 10px;
`;
