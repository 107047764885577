import React, { useState, useEffect, useCallback } from "react";
import FileSaver from "file-saver";
import { FaFileUpload, FaFileDownload } from "react-icons/fa";
import { Controller, useForm } from "react-hook-form";
import { RiErrorWarningFill } from "react-icons/ri";
import Button from "../../../../template/components/Button";
import Modal from "../../../../template/components/Modal";
import { WarningText, DivLink } from "./styles";
import {
  ButtonClose,
  CustomAiOutlineClose,
} from "../../../../template/components/Modal/styles";
import {
  Container,
  UploadButton,
  UploadButtonText,
  DownloadButton,
  DownloadButtonText,
  CentralizerText,
  CustomLabel,
  UploadLabel,
  Separator,
  UploadDiv,
  DivLocalButtons,
} from "../../styles";
import { CustomInput, CustomSelect } from "../../../../template/styles/styles";
import {
  BancoInterface,
  NotaFiscalInterface,
  PontosInterface,
} from "../../utils/types";
import { AiOutlinePlus } from "react-icons/ai";
import { PeriodoInterface } from "../../utils/types";
import { getToken } from "../../../../services/auth";
import api from "../../../../services/api";
import { dialogBox } from "../../../../template/utils/dialogBox";
import { FaEye } from "react-icons/fa";
import { parseJsonToTableDataList } from "../../../../template/utils/parser";
import CustomTable from "../CustomTable";
import NumberFormat from "react-number-format";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
  className?: string;
  quinzena: String;
  year: number;
  bancos: BancoInterface[];
  pontos: PontosInterface[];
  periodo: PeriodoInterface;
  periodoId?: number;
  notaFiscal?: NotaFiscalInterface | undefined;
  gestorLaticinio?: any;
  periodoLabel?: String;
}
const CadastrarModal = ({
  isOpen,
  toggle,
  className,
  quinzena,
  year,
  bancos,
  pontos,
  periodo,
  periodoId,
  notaFiscal,
  gestorLaticinio,
  periodoLabel,
}: IProps) => {
  const [pdfName, setPdfName] = useState<string>("nota_fiscal.pdf");
  const [pdfLink, setPdfLink] = useState<string>();
  const fields = ["Nota", "Submetida em", "Periodo", "Acao"];
  const [notaAviso, setNotaAviso] = useState<any>();
  const [notaFiscalSelected, setNotalFiscalSelected] = useState<number>();
  const [notasRows, setNotasRows] = useState<any>();
  const [formVisible, setFormVisible] = useState<Boolean>(false);
  const [notaPrincipal, setNotaPrincipal] = useState<any>(notaFiscal);
  const [editarNota, setEditarNota] = useState<boolean>(false);
  const [valorTotal, setValorTotal] = useState<number>(0);
  const [arquivoPdf, setArquivoPdf] = useState<any>();
  const [wasChange, setWasChange] = useState<boolean>(false);
  const [pdfLabel, setPdfLabel] = useState<boolean>(true);

  const {
    handleSubmit,
    register,
    control,
    errors,
    setError,
    watch,
    reset,
    clearErrors,
    setValue,
    getValues,
  } = useForm({
    mode: "onSubmit",
  });
  const [formMethod, setFormMethod] = useState<string>("post");

  const contratos = [
    { id: "1", descricao: "contrato 1" },
    { id: "2", descricao: "contrato 2" },
  ];

  const base_url = api.defaults.baseURL?.replace("/api/", "");

  const { valor_total_leite_bovino, valor_total_leite_caprino } = watch([
    "valor_total_leite_bovino",
    "valor_total_leite_caprino",
  ]);

  const priceFormatter = useCallback((value: any) => {
    if (!Number(value)) return "";
    const prefix = "R$ ";
    const amount = new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      minimumFractionDigits: 2,
    }).format(value / 100);
    return `${prefix}${amount}`;
  }, []);

  const priceFormatterEdit = useCallback((value: any) => {
    if (!Number(value)) return "";
    const prefix = "R$ ";
    const amount = new Intl.NumberFormat("pt-BR", {
      style: "decimal",
      minimumFractionDigits: 2,
    }).format(value);
    return `${prefix}${amount}`;
  }, []);

  const priceUnformatter = useCallback(
    (value: string) =>
      value
        ?.toString()
        .replace(/[R$ ]/g, "")
        .replace(".", "")
        .replace(",", "."),
    []
  );

  function verificaVazio(valor: string) {
    if (valor === "") {
      return 0;
    }
    return parseFloat(valor);
  }

  useEffect(() => {
    let soma =
      verificaVazio(priceUnformatter(valor_total_leite_bovino)) +
      verificaVazio(priceUnformatter(valor_total_leite_caprino));
    soma = parseFloat(soma.toFixed(2));
    setValorTotal(soma);
  }, [valor_total_leite_bovino, valor_total_leite_caprino]);

  useEffect(() => {
    setNotaPrincipal(notaFiscal);
  }, [notaFiscal]);

  useEffect(() => {
    let parsedNotas = parseNotasFiscais(notaPrincipal);
    let hasNota = Object.keys(notaPrincipal).length;

    if (hasNota) {
      setNotasRows(
        parseJsonToTableDataList(parsedNotas, ["nome", "created_at", "periodo"])
      );
    } else {
      setNotasRows([]);
    }
  }, [notaPrincipal]);

  function parseNotasFiscais(notaPrincipal: any) {
    let tmp = [];
    if (notaPrincipal !== undefined) {
      let dataFormated = `${notaPrincipal?.updated_at?.substring(
        8,
        10
      )}/${notaPrincipal?.updated_at?.substring(
        5,
        7
      )}/${notaPrincipal?.updated_at?.substring(0, 4)}`;
      const object = {
        id: notaPrincipal?.id,
        nome: notaPrincipal?.numero,
        created_at: dataFormated,
        periodo: periodoLabel,
      };
      tmp.push(object);

      let childObject;
      notaPrincipal?.notas_fiscais_auxiliares?.map((nota: any) => {
        dataFormated = `${nota?.updated_at?.substring(
          8,
          10
        )}/${nota?.updated_at?.substring(5, 7)}/${nota?.updated_at?.substring(
          0,
          4
        )}`;
        childObject = {
          id: nota?.id,
          nome: nota?.numero,
          ponto: nota.ponto,
          created_at: dataFormated,
          periodo: periodoLabel,
        };
        tmp.push(childObject);
      });
    }

    return tmp;
  }

  function addFormNota() {
    setNotalFiscalSelected(0);
    setFormVisible(true);
    setEditarNota(false);
    resetDados();
  }

  //Converte a data inicial do periodo para a data padrão do Brasil
  const translateDate = (data: string) => {
    let splitedDate = data.split("-");

    if (splitedDate[1] === "01") {
      return `${splitedDate[2]} de Janeiro`;
    } else if (splitedDate[1] === "02") {
      return `${splitedDate[2]} de Fevereiro`;
    } else if (splitedDate[1] === "03") {
      return `${splitedDate[2]} de Março`;
    } else if (splitedDate[1] === "04") {
      return `${splitedDate[2]} de Abril`;
    } else if (splitedDate[1] === "05") {
      return `${splitedDate[2]} de Maio`;
    } else if (splitedDate[1] === "06") {
      return `${splitedDate[2]} de Junho`;
    } else if (splitedDate[1] === "07") {
      return `${splitedDate[2]} de Julho`;
    } else if (splitedDate[1] === "08") {
      return `${splitedDate[2]} de Agosto`;
    } else if (splitedDate[1] === "09") {
      return `${splitedDate[2]} de Setembro`;
    } else if (splitedDate[1] === "10") {
      return `${splitedDate[2]} de Outubro`;
    } else if (splitedDate[1] === "11") {
      return `${splitedDate[2]} de Novembro`;
    }

    return `${splitedDate[2]} de Dezembro`;
  };

  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={() => handleToggle()}>
          <CustomAiOutlineClose /> Fechar
        </ButtonClose>
        {/* <ButtonSave
          type="submit"
          form="FormCadastrarNF"
          style={{
            backgroundColor: formState.isValid
              ? colors.greenOfficial
              : colors.mediumGrey,
          }}
          disabled={!formState.isValid}
        >
          {formState.isValid ? (
            <CustomAiOutlineCheck />
          ) : (
            <CustomAiOutlineClose />
          )}{" "}
          Salvar
        </ButtonSave> */}
      </React.Fragment>
    );
  };
  function handleToggle() {
    resetDados();
    setNotaAviso("");
    setFormVisible(false);
    toggle();
  }

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  const resetDados = () => {
    setValue("arquivo", undefined);
    setPdfName("nota_fiscal.pdf");
    setPdfLink("");
    setWasChange(false);
    setPdfLabel(true);
    // Seta o valor 0 para valor total bovino e caprino para evitar que o valor total final se mantenha o mesmo após a submissão do formulário
    setValue("valor_total_leite_bovino", 0);
    setValue("valor_total_leite_caprino", 0);
    reset();
  };

  const onSubmit = (data: any) => {
    const obj = getValues([
      "quantidade_leite_bovino",
      "quantidade_leite_caprino",
      "valor_total_leite_bovino",
      "valor_total_leite_caprino",
    ]);

    // Checa os valores dos campos quantidade bovino e caprino/ valor total bovino e caprino
    if (
      Object.entries(obj).every(
        ([key, value]) => !value || parseInt(value) === 0
      )
    ) {
      notify(
        "error",
        "Informe no mínimo uma quantidade e o valor total para um tipo de leite"
      );
      return;
    }

    const token: any = getToken();

    const formNF: any = document.querySelector("form#FormCadastrarNF");
    let formData = new FormData(formNF);
    formData.append("gestor_laticinio", gestorLaticinio.id);
    formData.append("periodo", String(periodoId));

    let oldData: any = formData.get("data");
    oldData += " 12:00:00"; //adicionando hora para corrigir erro de formato de data
    formData.delete("data");
    formData.append("data", oldData);

    let quantidadeBovino: any = formData.get("quantidade_leite_bovino");
    formData.set(
      "quantidade_leite_bovino",
      quantidadeBovino ? quantidadeBovino : "0"
    );

    let quantidadeCaprino: any = formData.get("quantidade_leite_caprino");
    formData.set(
      "quantidade_leite_caprino",
      quantidadeCaprino ? quantidadeCaprino : "0"
    );

    let totalBovino: any = formData.get("valor_total_leite_bovino");
    totalBovino = priceUnformatter(totalBovino ? totalBovino : "0");
    formData.delete("valor_total_leite_bovino");
    formData.append("valor_total_leite_bovino", totalBovino);

    let totalCaprino: any = formData.get("valor_total_leite_caprino");
    totalCaprino = priceUnformatter(totalCaprino ? totalCaprino : "0");
    formData.delete("valor_total_leite_caprino");
    formData.append("valor_total_leite_caprino", totalCaprino);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    };

    api[notaFiscalSelected ? "put" : "post"](
      `notas_fiscais/${notaFiscalSelected ? notaFiscalSelected + "/" : ""}`,
      formData,
      config
    )
      .then(() => {
        notify(
          "success",
          `Nota de recebimento ${
            notaFiscalSelected ? "atualizada" : "cadastrada"
          } com sucesso`
        );
        resetDados();
        setEditarNota(false);

        // Chamada utilizada para atualizar a lista de notas fiscais cadastradas após o cadastro e edição de notas fiscais
        api
          .get(
            `notas_fiscais/nota_principal/?periodo=${periodoId}&gestor_laticinio=${gestorLaticinio.id}`,
            config
          )
          .then((resultNota) => {
            setNotaPrincipal(resultNota.data);
          })
          .catch((err) => {
            notify(
              "error",
              "Erro na atualização da lista de notas fiscais cadastradas"
            );
          });
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          /* Pega os erros de status 400 e percorre o objeto contendo os erros retornados e seta mensagens customizadas
          para cada erro */

          const obj_msg: {
            [key: string]: string;
          } = {
            quantidade_leite_bovino: "Valor superior a 32767",
            quantidade_leite_caprino: "Valor superior a 32767",
            numero: "Ja existe alguma nota com esse numero",
            arquivo: "O tipo de arquivo não é permitido",
          };

          Object.entries(err.response?.data).map(([key, value]) => {
            setError(key, {
              type: "manual",
              message: obj_msg[key],
            });
          });

          notify(
            "error",
            `Erro ao ${
              notaFiscalSelected ? "Editar" : "cadastrar"
            } nota de recebimento`
          );
        } else if (err.response?.status === 500) {
          notify("error", "Erro no servidor");
        } else if (err.response?.status === 404) {
          notify("error", "Rota não encontrada");
        } else {
          notify("error", "Ocorreu um erro na requisição a api");
        }
      });
  };

  const onUpload = function (event: any) {
    event.preventDefault();
    if (event.target.files?.length && event.target.files !== undefined) {
      setWasChange(true);
      var file = event.target.files?.item(0);
      setPdfName(file.name);
      setPdfLabel(false);
    } else {
      setPdfName("nota_fiscal.pdf");
      setPdfLabel(true);
    }
  };

  const downloadFile = () => {
    if (arquivoPdf) {
      FileSaver.saveAs(arquivoPdf, pdfName);
    } else {
      notify("error", "Não existe arquivo válido para download");
    }
  };

  const toggleFormState = async (id: number) => {
    setNotalFiscalSelected(id);

    if (id === notaPrincipal?.id) {
      setValue(
        "quantidade_leite_bovino",
        notaPrincipal?.quantidade_leite_bovino
          ? notaPrincipal?.quantidade_leite_bovino
          : "",
        { shouldDirty: true }
      );
      setValue(
        "valor_total_leite_bovino",
        priceFormatterEdit(notaPrincipal?.valor_total_leite_bovino),
        { shouldDirty: true }
      );
      setValue(
        "quantidade_leite_caprino",
        notaPrincipal?.quantidade_leite_caprino
          ? notaPrincipal?.quantidade_leite_caprino
          : "",
        { shouldDirty: true }
      );
      setValue(
        "valor_total_leite_caprino",
        priceFormatterEdit(notaPrincipal?.valor_total_leite_caprino),
        { shouldDirty: true }
      );
      setValue("data", notaPrincipal?.data.slice(0, 10), { shouldDirty: true });
      setValue("numero", notaPrincipal?.numero, { shouldDirty: true });
      setValue("contrato", 1, { shouldDirty: true });
      setValue("banco", notaPrincipal?.banco.id, { shouldDirty: true });
      setValue("ponto", notaPrincipal?.ponto, { shouldDirty: true });

      if (notaPrincipal.arquivo) {
        const nomePdf = notaPrincipal?.arquivo.split("/").pop();
        const url = base_url + notaPrincipal?.arquivo;
        setPdfLink(url);
        setPdfName(nomePdf);

        try {
          const arquivo_data = await criarArquivo(url, nomePdf);
          setArquivoPdf(arquivo_data);
        } catch (err) {
          notify("error", "Ocorreu um erro ao obter o arquivo pdf");
        }
      } else {
        setPdfName("nota_fiscal.pdf");
        setPdfLink("");
        setArquivoPdf(undefined);
      }
    } else {
      const notaAuxiliar = notaPrincipal?.notas_fiscais_auxiliares.find(
        (item: any) => item.id === id
      );

      setValue(
        "quantidade_leite_bovino",
        notaAuxiliar?.quantidade_leite_bovino
          ? notaAuxiliar?.quantidade_leite_bovino
          : "",
        { shouldDirty: true }
      );
      setValue(
        "valor_total_leite_bovino",
        priceFormatterEdit(notaAuxiliar?.valor_total_leite_bovino),
        { shouldDirty: true }
      );
      setValue(
        "valor_total_leite_bovino",
        priceFormatterEdit(notaAuxiliar?.valor_total_leite_bovino),
        { shouldDirty: true }
      );
      setValue(
        "quantidade_leite_caprino",
        notaAuxiliar?.quantidade_leite_caprino
          ? notaAuxiliar?.quantidade_leite_caprino
          : "",
        { shouldDirty: true }
      );
      setValue("ponto", notaAuxiliar?.ponto, { shouldDirty: true });
      setValue("data", notaAuxiliar?.data.slice(0, 10), { shouldDirty: true });
      setValue("numero", notaAuxiliar?.numero, { shouldDirty: true });
      setValue("contrato", 1, { shouldDirty: true });
      setValue("banco", notaAuxiliar?.banco.id, { shouldDirty: true });

      if (notaAuxiliar.arquivo) {
        const nomePdf = notaAuxiliar?.arquivo.split("/").pop();
        const url = base_url + notaAuxiliar?.arquivo;
        setPdfLink(url);
        setPdfName(nomePdf);
        try {
          const arquivo_data = await criarArquivo(url, nomePdf);
          setArquivoPdf(arquivo_data);
        } catch (err) {
          notify("error", "Ocorreu um erro ao obter o arquivo pdf");
        }
      } else {
        setPdfName("nota_fiscal.pdf");
        setPdfLink("");
        setArquivoPdf(undefined);
      }
    }
    setPdfLabel(false);
  };

  // Pega a url com o arquivo pdf vindo do backend e transforma em um objeto File
  const criarArquivo = async (url: string, nomePdf: string) => {
    const response = await fetch(url);
    const data = await response.blob();
    const metadata = {
      type: "application/pdf",
    };
    const file = new File([data], nomePdf, metadata);
    return file;
  };

  const validarTipoArquivo = (arq: any) => {
    // parâmetro arq retorna uma lista de arquivos (FileList) e na primeira posição da lista se encontra o arquivo.
    if (arq[0] !== undefined) {
      if (arq[0].type !== "application/pdf") {
        return false;
      }
    }
    return true;
  };

  const validarData = (data: any) => {
    const dataAtual = new Date();
    const dataCampo = new Date(data);

    const diaAtual = dataAtual.getDate();
    const mesAtual = dataAtual.getMonth() + 1;
    const anoAtual = dataAtual.getFullYear();

    const dia = dataCampo.getDate() + 1;
    const mes = dataCampo.getMonth() + 1;
    const ano = dataCampo.getFullYear();

    if (anoAtual > ano) {
      return true;
    } else if (anoAtual === ano) {
      if (mesAtual > mes || (mesAtual === mes && diaAtual >= dia)) {
        return true;
      }
    }
    return false;
  };

  const checkQuantidade = (quant: string, value: string | undefined) => {
    if (quant !== "" && parseInt(quant) > 0 && (!value || value.length === 0)) {
      return false;
    }
    return true;
  };

  const checkValorTotal = (value: string | undefined, quant: string) => {
    if ((quant === "" || parseInt(quant) <= 0) && value) {
      return false;
    }
    return true;
  };

  function detailToUpdate(row: any) {
    setFormVisible(true);
    setWasChange(false);
    toggleFormState(row?.id);
    setEditarNota(true);
    clearErrors();
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => handleToggle()}
      modalTitle={`Cadastrar nota de recebimento para quinzena ${translateDate(
        String(quinzena)
      )} de ${year}`}
      footerContent={footerButtons()}
      className={className}
      fixed={true}
    >
      <Container>
        <span>
          notas fiscais cadastradas:
          <div>
            <div id="entries-table">
              <CustomTable
                fields={fields}
                rows={notasRows}
                hasSelection={false}
                hasSelectionAll={false}
                functions={[
                  {
                    name: "     Editar",
                    action: detailToUpdate,
                  },
                ]}
              />
            </div>
          </div>
        </span>
        <div id="createOptions" className="createOptions">
          <Button
            color="green"
            name="Nova nota"
            iconButtom={<AiOutlinePlus />}
            onClick={addFormNota}
          />
        </div>
        <hr
          style={{
            color: "blue",
            backgroundColor: "#0e4f9f",
            height: 1,
          }}
        />
        <WarningText hidden={!notaAviso}>
          <RiErrorWarningFill />
          {notaAviso}
        </WarningText>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="FormCadastrarNF"
          method={formMethod}
          hidden={!formVisible}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <CustomInput>
              <CustomLabel>Quantidade de leite bovino</CustomLabel>
              <input
                name="quantidade_leite_bovino"
                className={
                  errors.quantidade_leite_bovino
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                type="number"
                id="quantidade_leite_bovinoNF"
                placeholder={"0"}
                ref={register({
                  validate: {
                    isQuantZero: (value: any) =>
                      checkValorTotal(
                        getValues("valor_total_leite_bovino"),
                        value
                      ),
                  },
                })}
              />
              {errors.quantidade_leite_bovino?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.quantidade_leite_bovino?.type === "isQuantZero" && (
                <span className="error-message">
                  Informe uma quantidade de leite
                </span>
              )}
              {errors.quantidade_leite_bovino && (
                <span className="error-message">
                  {errors.quantidade_leite_bovino?.message}
                </span>
              )}
            </CustomInput>
            <CustomInput>
              <CustomLabel>Valor total leite bovino</CustomLabel>
              <Controller
                name="valor_total_leite_bovino"
                className={
                  errors.valor_total_leite_bovino
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                type="text"
                placeholder={"R$ 100.000,00"}
                rules={{
                  validate: {
                    isRequired: (value) =>
                      checkQuantidade(
                        getValues("quantidade_leite_bovino"),
                        value
                      ),
                  },
                }}
                as={NumberFormat}
                control={control}
                displayType="input"
                inputMode="numeric"
                defaultValue=""
                format={priceFormatter}
                isAllowed={(values: any) => {
                  const intVal = "".padStart(4, "9");
                  const decVal = "".padStart(0, "9");
                  const maxVal = parseFloat(`${intVal}.${decVal}`);
                  const { formattedValue, floatValue } = values;
                  return formattedValue === "" || floatValue / 10000 <= maxVal;
                }}
              />
              {errors.valor_total_leite_bovino?.type === "isRequired" && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <Separator />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <CustomInput>
              <CustomLabel>Quantidade de leite caprino</CustomLabel>
              <input
                name="quantidade_leite_caprino"
                className={
                  errors.quantidade_leite_caprino
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                type="number"
                id="quantidade_leite_caprinoNF"
                placeholder={"0"}
                ref={register({
                  validate: {
                    isQuantZero: (value: any) =>
                      checkValorTotal(
                        getValues("valor_total_leite_caprino"),
                        value
                      ),
                  },
                })}
              />
              {errors.quantidade_leite_caprino?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.quantidade_leite_caprino?.type === "isQuantZero" && (
                <span className="error-message">
                  Informe uma quantidade de leite
                </span>
              )}
              {errors.quantidade_leite_caprino && (
                <span className="error-message">
                  {errors.quantidade_leite_caprino?.message}
                </span>
              )}
            </CustomInput>

            <CustomInput>
              <CustomLabel>Valor total leite caprino</CustomLabel>
              <Controller
                name="valor_total_leite_caprino"
                className={
                  errors.valor_total_leite_caprino
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                type="text"
                placeholder={"R$ 100.000,00"}
                rules={{
                  validate: {
                    isRequired: (value) =>
                      checkQuantidade(
                        getValues("quantidade_leite_caprino"),
                        value
                      ),
                  },
                }}
                as={NumberFormat}
                control={control}
                displayType="input"
                inputMode="numeric"
                defaultValue=""
                format={priceFormatter}
                isAllowed={(values: any) => {
                  const intVal = "".padStart(4, "9");
                  const decVal = "".padStart(0, "9");
                  const maxVal = parseFloat(`${intVal}.${decVal}`);
                  const { formattedValue, floatValue } = values;
                  return formattedValue === "" || floatValue / 10000 <= maxVal;
                }}
              />
              {errors.valor_total_leite_caprino?.type === "isRequired" && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </CustomInput>
          </div>
          <Separator />
          <CustomInput>
            <CustomLabel>Valor total</CustomLabel>
            <strong style={{ fontSize: "16px" }}>
              {Number.isNaN(valorTotal)
                ? 0
                : valorTotal.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                  })}
            </strong>
          </CustomInput>
          <Separator />
          <div>
            <CustomLabel>Ponto de Distribuição</CustomLabel>
            <CustomSelect
              name="ponto"
              className={
                errors.banco ? "CustomSelect error-input" : "CustomSelect"
              }
              ref={register({
                validate: {
                  isQuantZero: (value: any) =>
                    checkValorTotal(getValues("ponto"), value),
                },
              })}
            >
              {pontos.map((ponto) => (
                <option key={ponto.id} value={ponto.id}>
                  {ponto.nome}
                </option>
              ))}
            </CustomSelect>
            {errors.banco && (
              <span className="error-message">Campo obrigatório</span>
            )}
          </div>
          <Separator />
          <CustomInput>
            <CustomLabel htmlFor="numeroNF">Data da nota</CustomLabel>
            <input
              name="data"
              className={
                errors.data ? "CustomInput error-input" : "CustomInput"
              }
              type="date"
              id="dataNF"
              ref={register({
                required: true,
                validate: {
                  isDataFutura: (data) => validarData(data),
                },
              })}
            />
            {errors.data?.type === "required" && (
              <span className="error-message">Campo obrigatório</span>
            )}
            {errors.data?.type === "isDataFutura" && (
              <span className="error-message">Data futura inválida</span>
            )}
          </CustomInput>
          <Separator />
          <CustomInput>
            <CustomLabel>Número da nota</CustomLabel>
            <input
              name="numero"
              className={
                errors.numero ? "CustomInput error-input" : "CustomInput"
              }
              type="number"
              id="numeroNF"
              ref={register({
                required: true,
                maxLength: 9,
              })}
            />
            {errors.numero?.type === "required" && (
              <span className="error-message">Campo obrigatório</span>
            )}
            {errors.numero?.type === "maxLength" && (
              <span className="error-message">
                Não deve possuir mais que 9 dígitos
              </span>
            )}
            {errors.numero && (
              <span className="error-message">{errors.numero?.message}</span>
            )}
          </CustomInput>

          <Separator />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="select-quinzena">
              <CustomLabel>Banco Laticínio</CustomLabel>
              <CustomSelect
                name="banco"
                className={
                  errors.banco ? "CustomSelect error-input" : "CustomSelect"
                }
                ref={register({ required: true })}
              >
                {bancos.map((banco) => (
                  <option key={banco.id} value={banco.id}>
                    {banco.codigo} - {banco.nome}
                  </option>
                ))}
              </CustomSelect>
              {errors.banco && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </div>

            <div className="select-quinzena">
              <CustomLabel>Contrato</CustomLabel>
              <CustomSelect
                name="contrato"
                className={
                  errors.contrato ? "CustomSelect error-input" : "CustomSelect"
                }
                ref={register({ required: true })}
              >
                {contratos.map((contrato) => (
                  <option key={contrato.id} value={contrato.id}>
                    {contrato.descricao}
                  </option>
                ))}
              </CustomSelect>
              {errors.contrato && (
                <span className="error-message">Campo obrigatório</span>
              )}
            </div>
          </div>
          <Separator />
          <CustomLabel>Anexo (.pdf)</CustomLabel>
          <UploadDiv>
            <UploadLabel
              htmlFor=""
              className={
                errors.arquivo ? "UploadLabel error-input" : "UploadLabel"
              }
              color={pdfLabel ? "#c8ced3" : "black"}
            >
              {pdfName}
            </UploadLabel>
          </UploadDiv>
          {errors.arquivo?.type === "required" && (
            <span className="error-message">Campo obrigatório</span>
          )}
          {errors.arquivo?.type === "tipoArquivo" && (
            <span className="error-message">Somente arquivos em .pdf</span>
          )}
          {errors.arquivo && (
            <span className="error-message">{errors.arquivo?.message}</span>
          )}
          <DivLink hidden={!pdfLink || wasChange}>
            <a className="link" href={pdfLink} target="_blank">
              <FaEye /> visualizar nota
            </a>
          </DivLink>
          <DivLocalButtons>
            {editarNota && arquivoPdf && !wasChange && (
              <DownloadButton type="button" onClick={() => downloadFile()}>
                <CentralizerText>
                  <DownloadButtonText>
                    <FaFileDownload
                      color="white"
                      style={{ marginRight: "10px" }}
                    />
                    Baixar Nota de recebimento
                  </DownloadButtonText>
                </CentralizerText>
              </DownloadButton>
            )}
            <UploadButton
              type="button"
              onClick={() => document.getElementById("anexoNF")?.click()}
            >
              <CentralizerText>
                <UploadButtonText>
                  <FaFileUpload color="white" style={{ marginRight: "10px" }} />
                  Enviar Nota de recebimento
                </UploadButtonText>
              </CentralizerText>
            </UploadButton>
          </DivLocalButtons>
          {/* <div hidden={!pdfLink}>
            <a className="link" href={pdfLink} target="_blank">
              {" "}
              <FaEye />
              visualizar nota{" "}
            </a>
          </div> */}
          <Separator />
          {/* {notaFiscal && (
            <CheckboxDiv>
              <input
                type="checkbox"
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
              <label>Atualizar nota fiscal principal</label>
            </CheckboxDiv>
          )} */}
          <Separator />
          <input
            id="anexoNF"
            type="file"
            name="arquivo"
            onChange={onUpload}
            accept="application/pdf"
            hidden
            ref={register({
              /* devido ao campo arquivo não poder ser preenchido através do setValue passando um arquivo(exige um FileList),
               na hora da edição o required não é levado em consideração (arquivo vai como null e o back identifica o arquivo 
               nulo e mantém o arquivo pdf que já estava adicionado na nota). O required só é considerado na edição se for informado um novo 
               arquivo para o campo na edição*/
              required: !editarNota || (editarNota && wasChange),
              validate: {
                tipoArquivo: (arq) => validarTipoArquivo(arq),
              },
            })}
          />
          <div id="createOptions" className="createOptions">
            <Button
              color="green"
              name={editarNota ? "Editar" : "Cadastrar"}
              iconButtom={<AiOutlinePlus />}
            />
          </div>
          <div className="updateOptions" hidden>
            <Button
              color="blue"
              name="Voltar"
              iconButtom={<AiOutlinePlus />}
              onClick={reset}
            />
            <Button color="blue" name="Limpar" iconButtom={<AiOutlinePlus />} />
          </div>
        </form>
      </Container>
    </Modal>
  );
};

export default CadastrarModal;
