import React, { useCallback, useEffect, useState } from "react";

import { pdf } from "@react-pdf/renderer";

import MainContainer from "../../../template/components/MainContainer";
import Layout from "../../../template/components/Layout";
import Button from "../../../template/components/Button";
import DocumentPDF from "./Components/DocumentPDF";
import { dialogBox } from "../../../template/utils/dialogBox";
import Table from "../../../template/components/Table";
import FileSaver from "file-saver";
import { CustomInput } from "../../../template/styles/styles";
import { RiPagesLine } from "react-icons/ri";
import { MdPictureAsPdf } from "react-icons/md";
import {
  parseDate2,
  parseJsonToTableDataList,
} from "../../../template/utils/parser";

import api from "../../../services/api";

import { Container, CustomSpinner } from "./styles";
import Pagination from "../../../template/components/Pagination";

import { useForm } from "react-hook-form";
import { PontoInterface } from "../../../template/utils/types";
import { FaFileCsv } from "react-icons/fa";
import { json2csv } from "json-2-csv";

const RelatorioEntregasLaticinio = () => {
  const [entregasReport, setEntregasReport] = useState<any[]>([]);
  const [municipios, setMunicipios] = useState<any[]>([]);
  const [municipioSelected, setMunicipioSelected] = useState<number>(-1);
  const [pontos, setPontos] = useState<PontoInterface[]>([]);
  const [pontoSelected, setPontoSelected] = useState<number>(0);
  const [dataInicial, setDataInicial] = useState<any>();
  const [dataFinal, setDataFinal] = useState<any>();
  const [entregas, setEntregas] = useState<any[]>([]);
  const [totalBovino, setTotalBovino] = useState(0);
  const [totalCaprino, setTotalCaprino] = useState(0);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [downloading, setDownloading] = useState<Boolean>(false);
  const { register, errors, getValues, handleSubmit } = useForm();
  const [csvCheck, setCsvCheck] = useState<boolean>(false);

  const [municipiosLoaded, setMunicipiosLoaded] = useState<boolean>(false);
  const [showAllFields, setShowAllFields] = useState<boolean>(false);

  const itemsPerPage = 10;

  //Titulo da tabela
  const fields = [
    "Colaborador do ponto",
    "Qtd Bovino",
    "Qtd Caprino",
    "Qtd Bovino Avariado",
    "Qtd Caprino Avariado",
    "Data",
  ];
  const fieldsAll = [
    "Município",
    "Ponto",
    "Colaborador do ponto",
    "Qtd Bovino",
    "Qtd Caprino",
    "Qtd Bovino Avariado",
    "Qtd Caprino Avariado",
    "Data",
  ];
  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  useEffect(() => {
    if (pontos.length > 0 && !municipiosLoaded) {
      const getMunicipios = async () => {
        const ids = pontos.map((ponto) => ponto.id).join("%2C");
        const { data } = await api.get(
          `municipios/pontos/?ponto_ids=${ids}&ordering=nome`
        );
        setMunicipios(
          data.sort((a: any, b: any) => a.nome.localeCompare(b.nome))
        );
        setMunicipiosLoaded(true);
      };
      getMunicipios();
    }
  }, [pontos, municipiosLoaded]);

  useEffect(() => {
    const loadPontos = async () => {
      try {
        let chamada = `pontos/?municipio=${municipioSelected}`;
        //Retorna todos os pontos cadastrados
        if (municipioSelected === -1) {
          chamada = `pontos/`;
        }
        const { data } = await api.get(chamada);

        setPontos(data.results);
      } catch (error) {
        notify("error", "Erro no servidor");
      }
    };
    loadPontos();
  }, [municipioSelected]);

  const loadEntregas = useCallback(async () => {
    try {
      if (
        getValues("dataInicial") === undefined ||
        getValues("dataFinal") === undefined ||
        municipioSelected === 0
      )
        return;

      let query;
      let dataIni = getValues("dataInicial");
      let dataFim = getValues("dataFinal");

      if (pontoSelected > 0) {
        query = `entregas/?limit=${itemsPerPage}&offset=${offset}&ponto=${pontoSelected}&data_inicial=${dataIni}&data_final=${dataFim}`;
      } else {
        query = `entregas/?limit=${itemsPerPage}&offset=${offset}&ponto=&data_inicial=${dataIni}&data_final=${dataFim}`;
      }
      //Busca os dados na API
      const { data } = await api.get(query);
      const { count, results } = data;

      if (results.length == 0) {
        notify("warning", "Sem entregas nesse período.");
      }
      //Adiciona o resultado ao array de entregas
      if (municipioSelected == -1) {
        setEntregas(
          parseJsonToTableDataList(results, [
            "municipio__nome",
            "ponto",
            "colaborador_ponto",
            "quantidade_leite_bovino",
            "quantidade_leite_caprino",
            "quantidade_leite_bovino_avariado",
            "quantidade_leite_caprino_avariado",
            "data",
          ])
        );
      } else {
        setEntregas(
          parseJsonToTableDataList(results, [
            "colaborador_ponto",
            "quantidade_leite_bovino",
            "quantidade_leite_caprino",
            "quantidade_leite_bovino_avariado",
            "quantidade_leite_caprino_avariado",
            "data",
          ])
        );
      }
      //Total de registros
      setTotal(count);
      //Calcula o total de leite
      calcTotalBovinoCaprino(results);
    } catch (error) {
      notify(
        "error",
        "Erro no Servidor, não foi possível gerar o os dados da tabela"
      );
      console.log(error);
    }
  }, [offset, municipioSelected, pontoSelected, notify]);

  function parseEntregasDocument(data: any[]) {
    const tmp = data.map((dt: any) => {
      return {
        Município: dt.municipio.nome,
        "Ponto de Distribuição": dt.ponto,
        Laticínio: dt.laticinio,
        "Colaborador do Ponto": dt.colaborador_ponto,
        "Quantidade leite bovino": dt.quantidade_leite_bovino,
        "Quantidade leite caprino": dt.quantidade_leite_caprino,
        "Quantidade leite bovino Avariado": dt.quantidade_leite_bovino_avariado,
        "Quantidade leite caprino Avariado":
          dt.quantidade_leite_caprino_avariado,
        Data: `${dt.data.split("T")[0].split("-")[2]}/${
          dt.data.split("T")[0].split("-")[1]
        }/${dt.data.split("T")[0].split("-")[0]}`,
      };
    });
    return tmp;
  }
  const getEntregasReport = async () => {
    try {
      if (
        getValues("dataInicial") === undefined ||
        getValues("dataFinal") === undefined ||
        municipioSelected === 0
      )
        return;
      let query;
      let dataIni = getValues("dataInicial");
      let dataFim = getValues("dataFinal");

      if (pontoSelected > 0) {
        query = `entregas/?limit=${itemsPerPage}&offset=${offset}&ponto=${pontoSelected}&data_inicial=${dataIni}&data_final=${dataFim}`;
      } else {
        query = `entregas/?limit=${itemsPerPage}&offset=${offset}&ponto=&data_inicial=${dataIni}&data_final=${dataFim}`;
      }
      //Busca os dados na API
      const { data } = await api.get(query);
      const { results } = data;
      setEntregasReport(results);
    } catch (error) {
      notify(
        "error",
        "Erro no Servidor, não foi possível gerar o os dados da tabela"
      );
      console.log(error);
    }
  };

  useEffect(() => {
    if (entregasReport?.length !== 0)
      csvCheck === true ? createCSV() : createPDF();
  }, [entregasReport]);

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  //Avança uma página
  const next = useCallback(() => {
    let newOffset = offset + itemsPerPage;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - itemsPerPage;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        itemsPerPage -
        itemsPerPage
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  const onSubmit = () => {
    if (municipioSelected != -1 && pontoSelected == -1) {
      notify("warning", "Selecione um ponto de distribuição");
      return;
    } else
      municipioSelected != -1
        ? setShowAllFields(false)
        : setShowAllFields(true);
    loadEntregas();
  };

  async function createPDF() {
    //Cria uma instância do PDF
    const blob = await pdf(
      <DocumentPDF
        data={entregasReport}
        count={entregasReport?.length}
        dateStart={dataInicial}
        dateEnd={dataFinal}
        totalBovino={totalBovino}
        totalCaprino={totalCaprino}
      />
    ).toBlob();
    //Obtem a data atual
    const today = new Date();
    const datenow =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    //Gera o download do PDF
    if (entregas.length > 0)
      FileSaver.saveAs(blob, `relatorio_entregas_por_ponto_${datenow}.pdf`);
    else {
      notify("warning", "Lista de entregas vazia");
    }
    setDownloading(false);
  }

  const createCSV = async () => {
    try {
      setDownloading(true);
      await json2csv(
        parseEntregasDocument(entregasReport),
        (err, csv) => {
          const csvData = new Blob([csv || ""], {
            type: "text/csv;charset=utf-8;",
          });
          FileSaver.saveAs(csvData, "Relatorio_Entregas_Laticinio.csv");
        },
        {
          excelBOM: true,
          delimiter: {
            field: ";",
          },
        }
      );
      setDownloading(false);
      setCsvCheck(false);
    } catch (err) {
      notify("error", "Ocorreu um erro ao gerar arquivo CSV");
      setDownloading(false);
      setCsvCheck(false);
    }
  };
  //Verifica o total de leite entregue
  function calcTotalBovinoCaprino(results: any) {
    let bovino = 0,
      caprino = 0;
    for (var i = 0; i < results.length; i++) {
      bovino += results[i].quantidade_leite_bovino;
      caprino += results[i].quantidade_leite_caprino;
    }
    setTotalBovino(bovino);
    setTotalCaprino(caprino);
  }

  return (
    <Layout>
      <MainContainer
        titlePage="Relatório de Entregas do Leite por Ponto"
        iconPage={<RiPagesLine />}
      >
        <Container>
          <div className="card mb-4">
            <div className="card-header d-flex align-items-center justify-content-between">
              <div>
                <h6>Filtro de pesquisa</h6>
              </div>
            </div>
            <div className="card-body">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="form filterForm"
              >
                <div className="form-row">
                  <div className="form-group col">
                    <label>Município</label>
                    <CustomInput>
                      <select
                        className={
                          errors.municipio
                            ? "CustomInput error-input"
                            : "CustomInput"
                        }
                        placeholder={"Selecione um município"}
                        name="municipio"
                        defaultValue={municipioSelected}
                        ref={register({
                          required: true,
                        })}
                        onChange={(e: any) => {
                          setMunicipioSelected(e.currentTarget.value);
                          setPontoSelected(-1);
                        }}
                        style={{ background: "white" }}
                      >
                        <option value={-1}>Todos</option>
                        {municipios.map((item) => (
                          <option value={parseInt(item.id)}>{item.nome}</option>
                        ))}
                      </select>
                      {errors.municipio && (
                        <span className="error-message">
                          Selecione um município
                        </span>
                      )}
                    </CustomInput>
                  </div>
                  <div className="form-group col">
                    <label>Ponto</label>
                    <CustomInput>
                      <select
                        className={
                          errors.ponto
                            ? "CustomInput error-input"
                            : "CustomInput"
                        }
                        name="ponto"
                        ref={register({
                          required: true,
                        })}
                        onChange={(e: any) => {
                          setPontoSelected(parseInt(e.currentTarget.value));
                        }}
                        style={{ background: "white" }}
                      >
                        <option value={-1}>Todos</option>
                        {pontos.map((item) => (
                          <option value={item.id}>{item.nome}</option>
                        ))}
                      </select>
                      {errors.ponto && (
                        <span className="error-message">
                          Selecione um ponto
                        </span>
                      )}
                    </CustomInput>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col">
                    <label htmlFor="dataInicial">Data inicial</label>
                    <CustomInput>
                      <input
                        type="date"
                        className={
                          errors.dataInicial
                            ? "CustomInput error-input"
                            : "CustomInput"
                        }
                        placeholder="Data de início"
                        name="dataInicial"
                        ref={register({
                          required: true,
                          validate: (value) => value <= parseDate2(new Date()),
                        })}
                        onChange={(e: any) => {
                          setDataInicial(e.currentTarget.value);
                        }}
                      />
                      {errors.dataInicial?.type === "required" && (
                        <span className="error-message">Campo obrigatório</span>
                      )}
                      {errors.dataInicial?.type === "validate" && (
                        <span className="error-message">Data inválida</span>
                      )}
                    </CustomInput>
                  </div>
                  <div className="form-group col">
                    <label htmlFor="dataFinal">Data final</label>
                    <CustomInput>
                      <input
                        type="date"
                        className={
                          errors.dataFinal
                            ? "CustomInput error-input"
                            : "CustomInput"
                        }
                        placeholder="Data de fim"
                        name="dataFinal"
                        ref={register({
                          required: true,
                          validate: (value) => value <= parseDate2(new Date()),
                        })}
                        onChange={(e: any) => {
                          setDataFinal(e.currentTarget.value);
                        }}
                      />
                      {errors.dataFinal?.type === "required" && (
                        <span className="error-message">Campo obrigatório</span>
                      )}
                      {errors.dataFinal?.type === "validate" && (
                        <span className="error-message">Data inválida</span>
                      )}
                    </CustomInput>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-sm btn-success"
                    onClick={(event) => {
                      if (
                        getValues("dataInicial") === undefined ||
                        getValues("dataFinal") === undefined
                      ) {
                        event.preventDefault();
                        notify("error", "Preencha todos os campos");
                      } else if (
                        getValues("dataInicial") > getValues("dataFinal")
                      ) {
                        event.preventDefault();
                        notify("error", "Data inicial maior que data final");
                      }
                      setOffset(0);
                      setPage(1);
                    }}
                  >
                    Pesquisar
                  </button>
                </div>
              </form>
            </div>
          </div>
          {showAllFields ? (
            <Table
              fields={fieldsAll}
              rows={entregas}
              hasSelection={false}
              hasSelectionAll={false}
            />
          ) : (
            <Table
              fields={fields}
              rows={entregas}
              hasSelection={false}
              hasSelectionAll={false}
            />
          )}

          <div className="containerFooterTable">
            Visualizando {entregas.length} de um total de {total} registros
            <div className=""></div>
            <Pagination
              itemCount={total}
              itemsPerPage={itemsPerPage}
              selectedPage={page}
              handlePageChange={changePage}
              handleNextPage={next}
              handlePreviousPage={previous}
              handleLastPage={last}
              handleFirstPage={first}
              maxPages={5}
            />
          </div>
          <div>Total de leite Bovino entregue: {totalBovino}</div>
          <div>Total de leite Caprino entregue: {totalCaprino}</div>
          <div>Total de leite entregue: {totalBovino + totalCaprino}</div>
          <div className="botoes" style={{ marginTop: 10 }}>
            <Button
              onClick={() => {
                if (
                  dataInicial === undefined ||
                  dataFinal === undefined ||
                  municipioSelected === 0
                ) {
                  notify("error", "Preencha todos os campos");
                } else if (dataFinal < dataInicial) {
                  notify("error", "Data inicial maior que data final");
                } else if (!downloading) {
                  setDownloading(true);
                  getEntregasReport();
                }
              }}
              name="Baixar PDF"
              color={downloading ? "grey" : "blue"}
              iconButtom={<MdPictureAsPdf />}
            />

            <Button
              onClick={() => {
                if (
                  dataInicial === undefined ||
                  dataFinal === undefined ||
                  municipioSelected === 0
                ) {
                  notify("error", "Preencha todos os campos");
                } else if (dataFinal < dataInicial) {
                  notify("error", "Data inicial maior que data final");
                } else if (!downloading) {
                  setDownloading(true);
                  setCsvCheck(true);
                  getEntregasReport();
                }
              }}
              name="Baixar CSV"
              color={downloading ? "grey" : "blue"}
              iconButtom={<FaFileCsv />}
            />

            {downloading && (
              <div>
                <CustomSpinner /> Processando
              </div>
            )}
          </div>
        </Container>
      </MainContainer>
    </Layout>
  );
};

export default RelatorioEntregasLaticinio;
